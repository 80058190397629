<template>
  <v-card flat class="pa-3 mt-2">
    <validation-observer ref="observer">
      <v-form class="multi-col-validation mt-6" @submit.prevent="submit">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Pilot Lincense Number" rules="required">
                <v-text-field
                  v-model="informationData.pilot_license_number"
                  :error-messages="errors"
                  label="Pilot Lincense Number"
                  required
                  dense
                  outlined
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="AV Sec" rules="required">
                <date-picker-dialog
                  v-model="informationData.avsec"
                  :minimum_date="`1950-01-01`"
                  :error-messages="errors"
                  :label="`AV Sec`"
                  @forceDateUpdate="forceDateUpdate"
                  required
                  dense
                  outlined
                ></date-picker-dialog>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Medical" rules="required">
                <date-picker-dialog
                  v-model="informationData.medical"
                  :minimum_date="`1950-01-01`"
                  :error-messages="errors"
                  :label="`Medical`"
                  @forceDateUpdate="forceDateMedicalUpdate"
                  required
                  dense
                  outlined
                ></date-picker-dialog>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" name="Pilot License Expiry" rules="required">
                <date-picker-dialog
                  v-model="informationData.pilot_license_expiry"
                  :minimum_date="`1950-01-01`"
                  :error-messages="errors"
                  :label="`Pilot License Expiry`"
                  @forceDateUpdate="forcePilotLicenseExpiry"
                  required
                  dense
                  outlined
                ></date-picker-dialog>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider name="Verification Document">
                <element-file-uploader
                  :uri="fileUri"
                  :media_file="`${media_path}${informationData.verification_doc}`"
                  ref="imageUploadComponent"
                  @getFileName="getFileName"
                ></element-file-uploader>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn type="submit" color="primary" class="me-3 mt-3">
            Save changes
          </v-btn>
          <v-btn outlined class="mt-3" color="secondary" type="reset" @click.prevent="resetForm">
            Cancel
          </v-btn>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from "@mdi/js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import ElementFileUploader from "@/components/ElementFileUploader.vue";
import DatePickerDialog from "@/components/DatePickerDialog.vue";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ElementFileUploader,
    DatePickerDialog,
  },
  props: {
    informationData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      icon: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      media: "",
    };
  },
  computed: {
    fileUri() {
      return this.$store.getters.fileUri;
    },
    media_path() {
      return this.$MEDIA;
    },
  },
  methods: {
    getFileName(value) {
      this.informationData.verification_doc = value;
    },
    forceDateUpdate(value) {
      this.informationData.avsec = value;
    },
    forceDateMedicalUpdate(value) {
      this.informationData.medical = value;
    },
    forcePilotLicenseExpiry(value) {
      this.informationData.pilot_license_expiry = value;
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.update(`${this.$IYA.URI.PROFILE__UPDATE}`, this.informationData).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
          } else {
            // eslint-disable-next-line
            if (response.data.error) {
              this.$swal(`Failed !`, `${response.data.error}`, `error`);
            } else {
              this.$swal(`Failed !`, `${response.message}`, `error`);
            }
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
