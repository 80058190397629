<template>
  <v-card flat class="pa-3 mt-2">
    <validation-observer ref="observer">
      <form id="account_details" class="multi-col-validation mt-6" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" sm="3" class="pa-10">
            <validation-provider name="File">
              <element-image-uploader
                :uri="fileUri"
                :media_file="`${media_path}${accountData.avatar}`"
                ref="imageUploadComponent"
                @getImageName="getImageName"
              ></element-image-uploader>
            </validation-provider>
          </v-col>
        </v-row>

        <v-card-text class="d-flex">
          <v-row>
            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="First Name" rules="required">
                <v-text-field
                  v-model="accountData.first_name"
                  :error-messages="errors"
                  label="First Name"
                  required
                  dense
                  outlined
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="Last Name" rules="required">
                <v-text-field
                  v-model="accountData.last_name"
                  :error-messages="errors"
                  label="Last Name"
                  required
                  dense
                  outlined
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="Email" rules="required">
                <v-text-field
                  v-model="accountData.email"
                  :error-messages="errors"
                  label="Email"
                  required
                  dense
                  outlined
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="Phone" rules="">
                <v-text-field v-model="accountData.phone" :error-messages="errors" label="Phone" dense outlined>
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="Pilot" rules="">
                <v-checkbox :error-messages="errors" v-model="accountData.pilot" :label="`Pilot`"></v-checkbox>
              </validation-provider>
            </v-col>

            <v-col md="6" cols="12">
              <validation-provider v-slot="{ errors }" name="Observer" rules="">
                <v-checkbox :error-messages="errors" v-model="accountData.observer" :label="`Observer`"></v-checkbox>
              </validation-provider>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" type="submit">
                Save changes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from "@mdi/js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import ElementImageUploader from "@/components/ElementImageUploader.vue";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ElementImageUploader,
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      media: "",
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    };
  },
  computed: {
    fileUri() {
      return this.$store.getters.fileUri;
    },
    media_path() {
      return this.$MEDIA;
    },
  },
  methods: {
    getImageName(value) {
      this.accountData.avatar = value;
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.update(`${this.$IYA.URI.PROFILE__EDIT}`, this.accountData).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.refresh();
          } else {
            this.$swal(`Failed !`, `${response.data.error}`, `error`);
          }
        });
        util.isLoading();
      }
    },
    async refresh() {
      await this.$store.dispatch(`${this.$IYA.NAMESPACE.AUTH}/${this.$IYA.ACTION.AUTHENTICATED_USER}`);
    },
  },
};
</script>
