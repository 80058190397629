var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{staticClass:"multi-col-validation mt-6",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Pilot Lincense Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Pilot Lincense Number","required":"","dense":"","outlined":""},model:{value:(_vm.informationData.pilot_license_number),callback:function ($$v) {_vm.$set(_vm.informationData, "pilot_license_number", $$v)},expression:"informationData.pilot_license_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"AV Sec","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"AV Sec","required":"","dense":"","outlined":""},on:{"forceDateUpdate":_vm.forceDateUpdate},model:{value:(_vm.informationData.avsec),callback:function ($$v) {_vm.$set(_vm.informationData, "avsec", $$v)},expression:"informationData.avsec"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Medical","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"Medical","required":"","dense":"","outlined":""},on:{"forceDateUpdate":_vm.forceDateMedicalUpdate},model:{value:(_vm.informationData.medical),callback:function ($$v) {_vm.$set(_vm.informationData, "medical", $$v)},expression:"informationData.medical"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Pilot License Expiry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"Pilot License Expiry","required":"","dense":"","outlined":""},on:{"forceDateUpdate":_vm.forcePilotLicenseExpiry},model:{value:(_vm.informationData.pilot_license_expiry),callback:function ($$v) {_vm.$set(_vm.informationData, "pilot_license_expiry", $$v)},expression:"informationData.pilot_license_expiry"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Verification Document"}},[_c('element-file-uploader',{ref:"imageUploadComponent",attrs:{"uri":_vm.fileUri,"media_file":("" + _vm.media_path + (_vm.informationData.verification_doc))},on:{"getFileName":_vm.getFileName}})],1)],1)],1)],1),_c('v-card-text',[_c('v-btn',{staticClass:"me-3 mt-3",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Save changes ")]),_c('v-btn',{staticClass:"mt-3",attrs:{"outlined":"","color":"secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }