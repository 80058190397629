<template>
  <v-card flat class="mt-5">
    <validation-observer ref="observer">
      <v-form @submit.prevent="submit">
        <div class="px-3">
          <v-card-text class="pt-5">
            <v-row>
              <v-col cols="12" sm="8" md="6">
                <!-- current password -->
                <validation-provider v-slot="{ errors }" name="Current Password" rules="required">
                  <v-text-field
                    v-model="currentPassword"
                    :type="isCurrentPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    label="Current Password"
                    outlined
                    dense
                    :error-messages="errors"
                    required
                    @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                  ></v-text-field>
                </validation-provider>

                <!-- new password -->
                <validation-provider v-slot="{ errors }" name="New Password" rules="required">
                  <v-text-field
                    v-model="newPassword"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    label="New Password"
                    outlined
                    dense
                    :error-messages="errors"
                    required
                    hint="Make sure it's at least 8 characters."
                    persistent-hint
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                  ></v-text-field>
                </validation-provider>

                <!-- confirm password -->
                <validation-provider v-slot="{ errors }" name="Confirm New Password" rules="required">
                  <v-text-field
                    v-model="cPassword"
                    :type="isCPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    label="Confirm New Password"
                    outlined
                    dense
                    class="mt-3"
                    :error-messages="errors"
                    required
                    @click:append="isCPasswordVisible = !isCPasswordVisible"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
                <v-img
                  contain
                  max-width="170"
                  src="@/assets/images/3d-characters/pose-m-1.png"
                  class="security-character"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <!-- divider -->
        <v-divider></v-divider>

        <div class="pa-3">
          <!-- action buttons -->
          <v-card-text>
            <v-btn type="submit" color="primary" class="me-3 mt-3">
              Save changes
            </v-btn>
            <v-btn color="secondary" outlined class="mt-3">
              Cancel
            </v-btn>
          </v-card-text>
        </div>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      isCurrentPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      currentPassword: "",
      newPassword: "",
      cPassword: "",
    };
  },
  methods: {
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.update(`${this.$IYA.URI.PROFILE__UPDATE__PASSWORD}`, {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          cPassword: this.cPassword,
        }).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
          } else {
            this.$swal(`Failed !`, `${response.data.error}`, `error`);
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
