<template>
  <el-upload
    class="avatar-uploader"
    :action="`#`"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" alt="Can't Display" class="avatar" />
    <img v-else-if="media_file" :src="media_file" alt="Can't Display" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
import UploadService from "@/core/services/UploadFilesService";

export default {
  props: {
    uri: {
      type: String,
      default: "upload uri",
    },
    media_file: {
      type: String,
      default: "",
    },
  },
  computed: {
    uploadDomain() {
      return this.$DOMAIN;
    },
  },
  data() {
    return {
      imageUrl: "",
      message: "",
    };
  },
  methods: {
    handleAvatarSuccess(response, file) {
      console.log(response);
      this.$emit("getImageName", response.data.file_name);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      UploadService.upload(file, this.uri)
        .then(response => {
          this.message = response.data.message;
          this.$emit("getImageName", response.data.data.file_name);
        })
        .catch(err => {
          // eslint-disable-next-line
          this.message = "Could not upload the image! " + err;
        });
      return false;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-upload__input {
  visibility: hidden;
}
</style>
